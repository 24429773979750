import './DeansNote.scss';

import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Core from 'components/core';

export interface Props {
  html: string;
  frontmatter: {
    name: string;
    closing: string | null;
    portrait: {
      sizes: {
        fixed: {
          width: number;
          height: number;
          src: string;
          srcSet: string;
        };
      };
    };
    signature: {
      sizes: {
        fluid: {
          aspectRatio: number;
          src: string;
          srcSet: string;
          sizes: string;
        };
      };
    };
  };
}

const DeansNote: React.FC<Props> = (props) => {
  const {
    html,
    frontmatter: { name, closing, portrait, signature }
  } = props;

  return (
    <div className="DeansNote">
      <Core.TertiaryHeading>Dean&rsquo;s Welcome</Core.TertiaryHeading>

      <Core.Markdown prerendered>{html}</Core.Markdown>

      <div className="DeansNote-footer">
        <Img
          fixed={portrait.sizes.fixed}
          className="DeansNote-footer-image"
          alt={`Portrait of ${name}`}
        />
        <div className="DeansNote-footer-signOff">
          <p>{closing ? closing : 'Warmly,'}</p>
          <Img
            fluid={signature.sizes.fluid}
            className="DeansNote-footer-signature"
            alt=""
          />
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export const DeansNoteFragment = graphql`
  fragment DeansNote on MarkdownRemark {
    html
    frontmatter {
      name
      closing
      portrait {
        sizes: childImageSharp {
          fixed(width: 100) {
            width
            height
            src
            srcSet
          }
        }
      }
      signature {
        sizes: childImageSharp {
          fluid(maxWidth: 220) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`;

export default DeansNote;
