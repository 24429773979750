import './Issues.scss';

import React from 'react';
import classnames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

import { useCurrentIssue } from 'context/IssueContext';

interface Issue {
  name: string;
  slug: string;
  digitalOnly: boolean;
  coverImage: {
    sizes: {
      thumb: {
        width: number;
        height: number;
        src: string;
        scrSet: string;
      };
    };
  };
}

interface PdfIssue {
  name: string;
  url: string;
  coverImage: {
    sizes: {
      thumb: {
        width: number;
        height: number;
        src: string;
        scrSet: string;
      };
    };
  };
}

const CoverIssues: React.FC = () => {
  const { issue: currentIssue } = useCurrentIssue();

  const data = useStaticQuery<{
    allIssuesYaml: { issues: Issue[] };
    allPdfIssuesYaml: { pdfIssues: PdfIssue[] };
  }>(graphql`
    query CoverIssuesQuery {
      allIssuesYaml {
        issues: nodes {
          name
          slug
          digitalOnly
          coverImage {
            sizes: childImageSharp {
              thumb: fixed(width: 130) {
                width
                height
                src
                srcSet
              }
            }
          }
        }
      }
      allPdfIssuesYaml {
        pdfIssues: nodes {
          name
          url
          coverImage {
            sizes: childImageSharp {
              thumb: fixed(width: 130) {
                width
                height
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `);

  const { issues } = data.allIssuesYaml;
  const { pdfIssues } = data.allPdfIssuesYaml;

  return (
    <div>
      <Core.TertiaryHeading>All Issues</Core.TertiaryHeading>

      <div className="CoverIssues-list">
        {issues.map((issue) => {
          const current = issue.slug === currentIssue.slug;
          const image = issue.coverImage.sizes.thumb;
          const imageScale = current ? 1 : 0.85;

          return (
            <Link
              key={issue.slug}
              className={classnames('CoverIssues-issue', {
                'CoverIssues-issue--current': current
              })}
              to={`/${issue.slug}`}
            >
              <div className="CoverIssues-issueCover">
                <img
                  className="CoverIssues-issueImage"
                  src={image.src}
                  width={image.width * imageScale}
                  height={image.height * imageScale}
                />
              </div>

              <div className="CovoerIssues-issueDetails">
                <div className="CoverIssues-issueName">{issue.name}</div>
                {issue.digitalOnly && (
                  <div className="CoverImage-digitalOnly">(Digital Only)</div>
                )}
              </div>
            </Link>
          );
        })}

        {pdfIssues.map((pdfIssue) => {
          const image = pdfIssue.coverImage.sizes.thumb;
          const imageScale = 0.85;

          return (
            <a
              key={pdfIssue.url}
              className="CoverIssues-issue"
              href={pdfIssue.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="CoverIssues-issueCover">
                <img
                  className="CoverIssues-issueImage"
                  src={image.src}
                  width={image.width * imageScale}
                  height={image.height * imageScale}
                />
              </div>

              <div className="CoverIssues-issue-PDFOnly">PDF Only</div>

              <div className="CovoerIssues-issueDetails">
                <div className="CoverIssues-issueName">{pdfIssue.name}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default CoverIssues;
