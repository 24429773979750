import './LatestIssueFlag.scss';

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

const LatestIssueFlag: React.FC = () => {
  const { latestIssue } = useStaticQuery<{
    latestIssue: {
      slug: string;
      name: string;
      coverImage: {
        sizes: {
          thumb: {
            width: number;
            height: number;
            src: string;
            scrSet: string;
          };
        };
      };
    };
  }>(graphql`
    query LatestIssueQuery {
      latestIssue: issuesYaml(latest: { eq: true }) {
        slug
        name
        coverImage {
          sizes: childImageSharp {
            thumb: fixed(width: 130) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  `);

  return (
    <Link to={`/${latestIssue.slug}`} className="LatestIssueFlag">
      <div className="LatestIssueFlag-details">
        <div className="LatestIssueFlag-heading">View the current issue</div>
        <div className="LatestIssueFlag-issueName">{latestIssue.name}</div>
      </div>

      <img
        src={latestIssue.coverImage.sizes.thumb.src}
        className="LatestIssueFlag-image"
      />
    </Link>
  );
};

export default LatestIssueFlag;
