import './Cover.scss';

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import FeatureBlock from 'components/FeatureBlock';
import LatestIssueFlag from './LatestIssueFlag';
import DeansNote, { Props as DeansNotesProps } from './DeansNote';
import Issues from './Issues';

interface Props {
  issue: {
    name: string;
    slug: string;
  };
  features: {
    title: string;
    label: string | null;
    subtitle: string;
    slug: string;
    author: string;
    issue: string;
    animatedCover: string;
    image: {
      sizes: {
        thumb: {
          width: number;
          height: number;
          src: string;
          srcSet: string;
        };
        full: {
          aspectRatio: number;
          src: string;
          srcSet: string;
          sizes: string;
        };
      };
    };
  }[];
  deansNote?: DeansNotesProps;
  coverFooter?: React.ReactNode;
}

const Cover: React.FC<Props> = (props) => {
  const { issue, deansNote, coverFooter } = props;
  const [firstFeature, ...features] = props.features;

  const { latestIssue } = useStaticQuery<{
    latestIssue: {
      slug: string;
    };
  }>(graphql`
    query LatestIssueSlugQuery {
      latestIssue: issuesYaml(latest: { eq: true }) {
        slug
      }
    }
  `);

  return (
    <Layout title="SAIS Magazine">
      <Core.ContentWrapper>
        <div className="Home-features">
          {issue.slug !== latestIssue.slug && (
            <div className="Home-latestIssueFlag">
              <LatestIssueFlag />
            </div>
          )}

          <div className="Home-features-largeImage">
            {firstFeature.animatedCover ? (
              <img src={firstFeature.animatedCover} />
            ) : (
              <Img fluid={firstFeature.image.sizes.full} alt="" />
            )}
          </div>
          <div className="Home-features-text">
            <Link to={`/${firstFeature.issue}/${firstFeature.slug}`}>
              <h1 className="Home-features-text-primaryHeading">
                {firstFeature.title}
              </h1>
              <Core.Flag>By {firstFeature.author}</Core.Flag>

              {firstFeature.label && (
                <p className="Home-features-text-label">{firstFeature.label}</p>
              )}

              <div>
                <p className="Home-features-text-subtitle">
                  {firstFeature.subtitle}
                </p>
              </div>
            </Link>
            <Core.HorizontalRule compact />
            {features.map((feature) => (
              <FeatureBlock key={feature.slug} feature={feature} />
            ))}
          </div>
        </div>

        <div className="Home-details">
          <div className="Home-details-contact">
            <Core.TertiaryHeading>Get in Touch</Core.TertiaryHeading>
            <p className="Home-deatils-contact-paragraph">
              Your contributions are part of what makes the magazine great. Our
              editorial staff would love to hear from you.
            </p>

            <div className="Home-details-contact-linkWrapper">
              <a
                href="mailto:saismagazine@jhu.edu"
                className="Home-details-contact-link"
              >
                Submit a Class Note →
              </a>
            </div>
            <div className="Home-details-contact-linkWrapper">
              <a
                href="https://sais.jhu.edu/alumni/alumni-perks-and-resources/update-information"
                target="_blank"
                rel="noopener noreferrer"
                className="Home-details-contact-link"
              >
                Update your Contact Information →
              </a>
            </div>
            <div className="Home-details-contact-linkWrapper">
              <a
                href="https://sais.jhu.edu/giving/make-gift"
                target="_blank"
                rel="noopener noreferrer"
                className="Home-details-contact-link"
              >
                Make a Gift →
              </a>
            </div>
          </div>

          {deansNote && <DeansNote {...deansNote} />}
        </div>

        {coverFooter && <>{coverFooter}</>}

        <Issues />
      </Core.ContentWrapper>
    </Layout>
  );
};

export default Cover;
