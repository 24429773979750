import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

import { useCurrentIssue } from 'context/IssueContext';

import CoverLayout from 'components/cover/Cover';
import SaveTheDate from 'components/cover/SaveTheDate';

import { Props as DeansNotesProps } from 'components/cover/DeansNote';

const Cover: React.FC = () => {
  const { issue, features } = useCurrentIssue();

  const { deansNote } = useStaticQuery<{
    deansNote: DeansNotesProps;
  }>(graphql`
    query {
      deansNote: markdownRemark(
        fileAbsolutePath: { regex: "/spring-2021/deans-note/" }
      ) {
        ...DeansNote
      }
    }
  `);

  return (
    <CoverLayout
      issue={issue}
      features={features}
      deansNote={deansNote}
      coverFooter={
        <SaveTheDate
          dates={[
            <div key={0}>
              <Core.BoldText>SAIS Europe Alumni Weekend 2021 </Core.BoldText>
              <br />
              Friday, June 4<sup>th</sup> – Saturday, June 5<sup>th</sup>
              <br />
              <Core.ExternalLink href="https://europe.jhu.edu/aw2021/library.cfm">
                SAIS Europe 66th Anniversary - Alumni Weekend 2021
              </Core.ExternalLink>
            </div>,
            <div key={1}>
              <Core.BoldText> Amici di Bologna</Core.BoldText>
              <br />
              Saturday, October 9, 2021
              <br />
              New York, NY
            </div>,
            <div key={2}>
              <Core.BoldText>
                The Hopkins-Nanjing Center 35th Anniversary Celebration
              </Core.BoldText>
              <br />
              Thursday, October 22<sup>nd</sup> – Saturday, October 24
              <br />
              <Core.ExternalLink href="https://sais.jhu.edu/35th-anniversary">
                35th Anniversary | Johns Hopkins SAIS
              </Core.ExternalLink>
            </div>
          ]}
        />
      }
    />
  );
};

export default Cover;
