import './SaveTheDate.scss';

import React from 'react';

import * as Core from 'components/core';

interface Props {
  dates: React.ReactNode[];
}

const SaveTheDate: React.FC<Props> = (props) => {
  const { dates } = props;

  return (
    <div className="SaveTheDate">
      <div>
        <Core.SecondaryHeading>Save the Date</Core.SecondaryHeading>

        <div className="SaveTheDate-content">
          <div className="SaveTheDate-dates">{dates}</div>

          <div>
            Please visit{' '}
            <a
              href="http://www.sais-jhu.edu/alumni"
              target="_blank"
              rel="noopener noreferrer"
              className="SaveTheDate-link"
            >
              sais-jhu.edu/alumni
            </a>{' '}
            for more information on upcoming events.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveTheDate;
